@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  padding: 0;
  margin: 0;
}
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 100px;
  border: 2px solid var(--primary);
}

:root {
  --primary: #f1f5f9;
  --secondary: #fca317;
}



@layer {
  #root {
    font-family: "Roboto", sans-serif;
  }

  
}


   






