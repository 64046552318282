/* Checked State */
.custom-switch-checked {
  background-color: #105d50 !important; 
  font-size: "12px" !important;/* Change to your desired color for the checked state */
}

/* Unchecked State */
.custom-switch-unchecked {
  background-color: gray !important; 
  font-size: "12px" !important;/* Change to your desired color for the unchecked state */
}

